.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  border-radius: 10px;
  min-width: 330px;
  max-width: 600px;
  color: white;
  box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.5);
  @media (max-width: 600px) {
    width: 90vw;
  }
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 20px 20px 20px;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-background-first);
  border-radius: 0 0 10px 10px;
  gap: 10px;
  padding: 15px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  .nextButton {
    margin-left: auto;
    @media (max-width: 1100px) {
      margin-left: 0;
    }
  }
}

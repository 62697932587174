@import '../../../styles/mixins';

.tariff {
  @include container(var(--color-background-main),var(--color-border-first));
  gap: 20px;
  justify-content: space-between;
  width: auto;

  .containerOne {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    justify-content: space-between;
    color: var(--color-text-gray);
    .title {
      color: var(--color-text-white);
    }
  }
  @media (max-width: 750px) {
    gap: 10px;
  }
  .containerTwo{
    display: flex;
    flex-direction: column;
    width: 160px;
    min-width: 160px;
    text-align: right;
    white-space: nowrap;
    justify-content: space-between;
    .price {
      display: flex;
      flex-direction: column;
      @media (max-width: 750px) {
        flex-direction: row;
        justify-content: space-between;
      }
      @media (max-width: 400px) {
        flex-direction: column;
      }
    }
    .oldPrice {
      text-decoration: line-through;
      color: red;
    }
  }
  @media (max-width: 750px) {
    flex-direction: column;
    .containerTwo {
      display: flex;
      text-align: left;
      gap: 10px;
      width: auto;
      min-width: auto;
    }
  }
}







///* .titleDiv {
//    width: 100%;
//    text-align: center;
//} */
//.oneProductDiv {
//    width: 100%;
//    padding: 15px;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    justify-content: space-between;
//    gap: 10px;
//    border-radius: 15px;
//    background-color: var(--color-background-main);
//}
//
//.titleDiv {
//    width: 100%;
//    text-align: center;
//}
//.btn_class {
//    height: 35px;
//    width: 80%;
//}
//.showCats_label {
//    font-size: 14px;
//    color: rgb(178, 176, 176);
//    cursor: pointer;
//}
//
//
//.info_div {
//    display: flex;
//    flex-direction: row;
//    gap: 5px;
//}
//.labelS {
//    text-decoration: line-through;
//    color: red;
//}
//
//.PayButton {
//    max-width: 350px;
//}
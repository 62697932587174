.columnsWide { 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px;
    height: 50px;
}

.buttonSection {
    position: relative;
    border-radius: 5px;
    background-color: var(--gptAnswer_button_bgColor);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    cursor: pointer;
}
.buttonSection_img {
    width: 24px;
    height: 24px;
    transition: var(--transition-short);
}
.buttonSection:hover { 
    background-color: #F0EFEF;
    scale: 1.05; 
}

.buttonSection:hover::after {
    /* min-width: 100px; */
    content: attr(data-title); /* Выводим текст */
    /* позиционирование */
    position: absolute; 
    /* Положение подсказки */
    /* left: 20%; top: 30%;  */
    /* left: -100px; top: -10px; */
    bottom: -18px;
    z-index: 211; /* Отображаем подсказку поверх других элементов */
    /* Полупрозрачный цвет фона */
    /* background: rgba(255,255,230,0.9); */
    /* Гарнитура шрифта */
    /* font-family: Arial, sans-serif;  */
    /* Размер текста подсказки */
    font-size: 11px; 
    /* Поля */
    padding: 5px 10px; 
    /* Параметры рамки */
    /* border: 1px solid #333; */
    color: black;

    white-space: nowrap;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-end; */
}

.buttonSectionSelected {
    background-color: #FFC800;
}
.buttonSection_disabled {
    background-color: grey;
}
.longDiv {
    flex: 1;
}

.snippet_class {
    min-width: 230px;
    position: absolute;
    top: 50px;
    left: 0;

    border-radius: 5px;

    z-index: 300;
    background-color: #FFFFFF;
    font-size: 13px;
    color: #000000;

    display: grid;
    grid-template-rows: 39px 39px 39px;

    cursor: auto;
    overflow: hidden;
}
.snippet_elem_class {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
}
.snippet_elem_class:hover {
    background-color: #F0EFEF;
}
.copy_label_text {
    color: #81848B;
    cursor: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
}
.copy_img_div {
    width: 75px;
    height: 50px;
}
.copy_img_class {
    width: 24px;
    height: 24px;
    transition: var(--transition-short);
}
.rotate_img {
    transform: rotate(180deg);
}
.bottomWrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-background-main);
  border: 1px solid var(--color-border-main);
  color: var(--color-text-white);
}

.oneField_class {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .button {
    width: 48%;
  }
  @media (max-width: 1300px) {
    .button {
      padding: 6px 10px;
    }
  }
}
.noSubscriptions {
  display: flex;
  font-size: 2.8rem;
  text-align: center;
  font-family: "Formular", sans-serif;
  font-weight: 999;
  line-height: 60px;
  letter-spacing: -3px;
  color: var(--color-background-first);
}
.AdditionsProduct {
  width: 95%;
  max-width: 700px;
}

.homeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: var(--color-background-first);
  border: 1px solid var(--color-border-main);
  color: var(--color-text-white);
  padding: 20px;
  border-radius: 20px;
  @media (max-width: 550px) {
    border-radius: 0;
  }
}
.news {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mainDescriptionDiv {
  display: flex;
  width: 100%;
  height: 100%;
}
.wellcomeTitle {
  color: var(--color-text-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wellcomeText {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 15px;
}
.wellcomeText label {
  color: var(--color-text-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.wellcomeText a {
  color: var(--color-text-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.5;
}

.rightLogoDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.rightLogoDiv img {
  height: 40px;
  width: 40px;
}

.profileWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-background-first);
  border: 1px solid var(--color-border-first);
  border-radius: 20px;
  @media (max-width: 550px) {
    border-radius: 0;
  }
}

@media screen and (min-width: 885px) {
  .profileMainAreaWrapper {
    display: grid;
    grid-template-areas:
      "a b"
      "a c";
    grid-template-columns: 1fr 1.6fr;
    gap: 20px;

    margin: 0 30px 0 30px;
    width: 100%;
  }
}
@media screen and (max-width: 884px) {
  .profileMainAreaWrapper {
    display: grid;
    grid-template-areas:
      "a"
      "b"
      "c";
    gap: 20px;
    margin: 0 30px 0 30px;
    width: 100%;
  }
}

.profileMainAreaLeft {
  grid-area: a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  color: var(--color-text-white);
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 884px) {
    min-height: 560px;
  }
  @media (max-width: 550px) {
    min-height: 480px;
  }
}

.profileMainAreaRight {
  grid-area: b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  color: var(--color-text-white);
}

.profileMainAreaBottom {
  grid-area: c;
  width: 100%;
  color: var(--color-text-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.oneSmallSection {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--color-background-main);
  border: 1px solid var(--color-border-main);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  .bottom {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 30px;
    @media (max-width: 884px) {
      flex-direction: column;
      gap: 20px;
    }
    .buttonRates {
      width: 48%;
      @media (max-width: 884px) {
        width: 100%;
      }
    }
  }
}

label.text {
  color: var(--color-text-white);
}


label.disabled {
  color: var(--color-dark-300);
}


.content {
  z-index: 1;
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  width: var(--radix-select-trigger-width);
  color: var(--color-text-white);
  background-color: var(--color-background-first);
  &[data-disabled] {
    cursor: initial;
    color: var(--color-text-white);
  }

  &[data-state='open'] {

  }

  .text {
    line-height: 16px;
    &:hover {
      color: var(--color-accent-300);
    }

    &:active {
      color: var(--color-text-white);
    }
  }

}
.icon {
  svg {
    width: 25px;
    height: 25px;
  }
}
.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: auto;
  cursor: pointer;
  height: 42px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--color-text-white);
  border: 1px solid var(--color-border-first);
  box-shadow: 0 0 20px 8px var(--color-shadow-first) inset;
  background: var(--color-background-first);

  &[data-disabled] {
    cursor: initial;
    color: var(--color-dark-300);
  }

  &[data-state='open'] {

  }


  //&:focus {
  //  border: 1px solid var(--color-info-700);
  //  outline: 1px solid var(--color-info-700);
  //}

  &:hover:not([data-disabled]) {
    filter: brightness(1.1);
    background: var(--color-background-first);
  }

  &[data-state='open'] .icon {
    transform: rotateX(180deg);
  }

  &[data-disabled] .text {
    color: var(--color-dark-300);
  }

  &[data-disabled] svg path {
    fill: var(--color-dark-300);
  }
}

.items {
  display: flex;
  flex-direction: column;
}
.checkBox {
  width: 100%;
  height: 100%;
}


.selectItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 6px;
  font-size: 16px;
  line-height: 18px;
  width: var(--radix-select-trigger-width);
  border: 1px solid var(--color-text-white);
  background-color: var(--color-background-first);
  border-top: none;
  border-bottom: none;
  height: 38px;
  &:last-child {
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid var(--color-text-white);
  }
  &:first-child {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid var(--color-text-white);
  }

  &:hover {
    background-color: var(--hover-item);
  }

  &:active:not([data-disabled]) {
    background-color: var(--active-item);
  }

  &[data-highlighted] {
    background-color: var(--hover-item);
    outline: none;
  }
}
.disabled {
  background-color: var(--color-background-first);
  &:hover:not([data-disabled]) {
    background-color: var(--color-background-first);
  }
  &:active:not([data-disabled]) {
    background-color: var(--color-background-first);
  }
}


.default {
  width: 100%;
}

.fullWidth {
  width: 100%;
}
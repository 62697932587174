.SectionMenu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: end;
  @media (max-width: 550px) {
    align-items: center;
  }
  gap: 2px;
}

.Child {
  display: flex;
  border-radius: 5px;
  width: 295px;
  min-width: 295px;
  @media (max-width: 550px) {
    width: 100%;
    min-width: 100%;
  }
  .Link {
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    gap: 10px;
    height: 38px;
    border-radius: 5px;
    color: white;
    line-height: 16px;
    transition: .3s;
    cursor: pointer;
    svg {
      stroke: var(--color-text-white);
      fill: var(--color-text-white);
      width: 20px;
      height: 20px;
    }
    &:hover {
      background: var(--hover-item);
    }
  }
  .LinkVision {
    &:hover {
      background: #986ad3;
    }
  }
  .Active {
    background-color: var(--active-item)
  }
  .ActiveVision {
    background-color: #AC6AFF;
  }
  .Title {
    line-height: 16px;
  }
  .New {
    color: var(--color-text-additional);
    svg {
      stroke: var(--color-icon-main);
    }
  }
  .isNotViewedAnswer {
    color: #fdc600
  }
}

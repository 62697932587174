.popup_message_wrapper {
    z-index: 1000;

    position: absolute;
    top: calc(100% - 7px);

    background-color: #3D4348;
    padding: 20px;
    border: 1px solid #292E32;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    text-align: left;
}
.popup_message_mobile {
    right: 0;
    min-width: 200px;
}
.popup_message_desctop {
    /* left: 0; */
    min-width: 300px;
    margin-left: 13px;
}

.popup_message_translate_mobile {
    transform: translate(-5%, -50%);
    right: 0;
    min-width: 200px;
}
.popup_message_translate_desctop {
    transform: translate(20%, -60%);
    left: 0px;
    min-width: 300px;
}
.oneQuestionOptionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radioInputDiv {
    min-height: 25px;
    display: grid;
    grid-template-columns: 25px 1fr;
    align-items: start;
    cursor: pointer;
    gap: 5px;
}
.radioInputDiv label {
    cursor: pointer;
    font-size: var(--font-size-m);
}

.radioInputCircle {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

.radioInputCircle::before {
    content: '';
    position: absolute;

    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .radioInputCircle:checked:before {
    border: 2px solid #FFC801;
  }
  .radioInputCircle:checked::after {
    content: '';
    position: absolute;

    /*2px - это ширина border*/
    top: calc(50%); 
    left: calc(50%); 
    transform: translate(-50%, 25%);

    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFC801;
    visibility: visible;
  }
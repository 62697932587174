.oneWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  /* background-color: red; */
  /* margin: 10px 0 0 0; */

  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 1.3;
}

.notRequiredField {
  margin: 0 5px;
  color: gray;
}

@import "../../styles/mixins";

.Layout {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.Wrapper {
  @include container(var(--color-background-first), var(--color-border-first));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  max-height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  gap: 20px;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.5);
  @media (max-width: 520px) {
    width: 90%;
  }
  h2 {
    padding: 20px;
  }
}

.closeButton {
  position: absolute;
  right: -7px;
  top: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-white);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transform: rotate(90deg) scale(1.1);
  }
}

.Loader {
  svg {
    width: 60px;
    height: 60px;
    animation: spin 2.5s infinite ease, round 1.7s infinite ease;
  }
}

.absolute {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.container {
  display: flex;
  margin: auto;
  svg {
    width: 40px;
    height: 40px;
    animation: spin 2.5s infinite ease, round 1.7s infinite ease;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg) ;
  }
  80% {
    transform: scale(1.3);
  }
  100% {
    transform: rotate(720deg);
  }
}

.Modal {
  color: white;
  width: 800px;
  padding:10px 0 0 0;
  gap: 0;
  @media (max-width: 900px) {
    width: 90%;
  }
  @media (max-width: 550px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: 100%;
    padding-top: 10px;
  }
}
.Scroll {
  width: 100%;
  padding: 10px;
}
.news {
  max-height: calc(90vh - 80px);
  padding: 0 10px;
  .sliderWrapper {
    width: 760px;
    @media (max-width: 900px) {
      width: calc(90vw - 40px);
    }
    @media (max-width: 550px) {
      width: calc(100vw - 40px);
    }
    padding-bottom: 30px;
    .imgWrapper {
      padding: 5px;
      transition: .4s;
    }
    img {
      max-width: 100%;
      aspect-ratio: 16 / 9;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      -webkit-box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
      -moz-box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
      box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
      @media (max-width: 900px) {
        height: 40svw;
      }
    }
  }
  .title {
    padding: 10px 0 30px 0;
  }

}
.bottom {
  .bottomContainer {
    display: flex;
    align-items: center;
  }
  .linkContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    -webkit-line-clamp: 1;
    transition: .2s;
    color: var(--color-text-gray);
    &:hover {
      color: var(--color-text-white)
    }
    svg {
      width: 25px;
    }

  }
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--color-background-main);
  border-radius: 0 0 10px 20px;
  @media (max-width: 550px) {
    border-radius: 0;
  }
}


.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.viewport {
  width: 100%;
  height: 100%;
}

.scrollbar {
  touch-action: none;
  user-select: none;
  display: flex;
}

.scrollbar[data-orientation='vertical'] {
  width: 6px;
  padding: 3px 0;
}

.scrollbar[data-orientation='horizontal'] {
  flex-direction: column;
  height: 14px;
  padding: 0 3px;
}

.thumb {
  cursor: pointer;
  position: relative;
  flex-grow: 1;
  border-radius: 5px;
  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: var(--color-scroll);
    border-radius: 3px;
    transition: 0.4s;
    [data-orientation='horizontal'] & {
      width: 100%;
      height: 4px;
    }
  }

  [data-orientation='horizontal'] & {
    padding: 5px 0;
    display: none;
  }

  &:hover::before {
    background-color: white;
  }
}

.parentCatWrapper {
  width: 100%;
  padding: 20px;
  background-color: var(--color-background-first);
  color: var(--color-text-white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 550px) {
    border-radius: 0;
  }
}
.Title {
  padding: 0 20px;
  line-height: 100px;
}
.Description {
  text-align: left;
  width: 100%;
}

.title_with_back_button {
  min-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title_with_back_button h1 {
  width: 100%;
  text-align: center;
}
.title_with_back_button img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

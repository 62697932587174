
  .Link {
    position: relative;
    width: 100%;
    height: 55px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text-white);
    background: var(--color-background-main);
    border: 1px solid var(--color-border-first);
    transition: 0.2s ;
    cursor: pointer;
    &:hover {
      background: var(--hover-item);
      transition: 0.2s ;
    }
    .SVGIcon {
      display: inline-block;
      width: 28px;
      height: 28px;
      stroke: var(--color-icon-main);
    }
    .linkContainer {
      width: 100%;
      display: flex;
      padding: 0 0 0 10px;
      gap: 10px;
      align-items: center;
    }

    .TitleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      width: 220px;
      min-width: 220px;
      line-height: 15px;
      @media (max-width: 550px) {
        width: 100%;
        min-width: 100%;
      }
      .title {
        display: flex;
        align-items: center;
        height: 55px;
        width: 200px;
        min-width: 220px;
        @media (max-width: 550px) {
          padding-right: 30px;
          width: 100%;
          min-width: 100%;
        }
      }
    }

  }
  .Support {
    .SVGIcon {
      display: inline-block;
      width: 28px;
      height: 28px;
      fill: var(--color-icon-first);
      stroke: none;
    }
  }
  .Notice {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: -6px;
    gap: 3px;
    .New {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 20px;
      width: 60px;
      font-weight: 600;
      font-size: 15px;
      background-color: var(--color-icon-main);
      color: black;
    }
    .NewAnswer{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #fdc600;
      color: black;
      height: 20px;
      width: 20px;
      img {
        width: 18px;
      }
    }
  }
  .Active {
    background-color: var(--active-item);
  }
.ArrowIcon {
  width: 45px;
  height: 45px;
  padding: 10px 10px;
  transition: 0.2s ;
  fill: var(--color-icon-first);
  &:hover {
    transform: scale(2);
  }
}
  .SVGIcon {
    width: 30px;
    height: 30px;
    stroke: var(--color-icon-main);
    fill: var(--color-icon-main);
  }
.ArrowIconHidden  {
  width: 0;
}

.supportWrapper {
  display: flex;
}


.checker {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: var(--transition-short);
}
.checker_border_white {
    border: 2px solid white;
}
.checker_border_yellow {
    border: 2px solid var(--color-button-primary);
}

.inner_checker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: var(--transition-short);

    width: 10px;
    height: 10px;
    border-radius: 50%;

}
.inner_checker_yellow {
    background-color: var(--color-button-primary);
}
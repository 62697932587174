.accountInfoWrapper {
    height: 45px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background-main);
    border: 1px solid var(--color-border-first);
    border-radius: 25px;
    margin-right: 20px;
    padding-right: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* Для Safari и старых версий Chrome */
    user-select: none;
    svg {
        width: 20px;
        height: 20px;
        fill: var(--color-icon-first);
    }
}

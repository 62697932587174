@import '../../../../styles/mixins';

.ChangePhotos {
  @include container(var(--color-background-first),var(--color-border-first));
  position: relative;
  height: 100%;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.ChoosingPhotoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
}
.Text {
  margin: 10px 0;
  text-align: left;
  width: 100%;
}
.ProfileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
}

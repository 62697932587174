.lineTextField {
    height: var(--textField_height);
    border-radius: 5px;

    padding: 0 10px;
    font-size: 16px;
}
.lineTextField::placeholder {
    font-size: 14px;
    font-style: italic;
}
.normalTextField {
    background-color: var(--textFiled_bgColor);
    color: black;
}
.errorTextField {
    background-color: var(--textField_error_bgColor);
    color: antiquewhite;
}

.inputPhoneDiv {
    color: black;
}
.inputPhone {
    height: calc(5px + var(--textField_height));
    width: calc(100% - var(--phoneField_iconWidth));
    margin-left: var(--phoneField_iconWidth);

    border-radius: 5px;

    padding: 0 10px;
    font-size: 16px;

}
.phoneButtonClass {
    /* background-color: red; */
    /* color: green; */
    border-radius: 10px 10px 10px 10px;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  line-height: 16px;
  margin: 0 10px;
  &.disabled {
    cursor: initial;
    color: #7f7f7f;
  }

  .root {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 1;
    width: 20px;
    height: 20px;
    transition: .3s;
    background-color: transparent;
    border-radius: 2px;

    &:not([data-state='checked']) {
      border: 2px solid #c1bfc5;
    }

    &:hover:not([data-disabled]) {
      background-color: rgba(255, 255, 255, 0.05);
      border: 2px solid #fdfdfd;
    }

    &[data-state='checked'] {
      background-color: #000000;
    }

    &[data-disabled] {
      pointer-events: none;
      cursor: initial;
      border: 2px solid #7f7f7f;
    }

    &:hover:active([data-state='checked']) {
      background-color: #7f7f7f;
    }

    &:hover:active:not([data-disabled]) {
      background-color: #000000;
      border: 2px solid #fdfdfd;
    }

    &:hover:active:not([data-state='checked'], [data-disabled]) {
      background-color: #7f7f7f;
    }

    &[data-state='checked'][data-disabled] {
      background-color: #dad8de;
    }
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;

    .indicator {
      display: flex;
      width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
    }
  }
}

.headerDiv {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: var(--header_height);
  background-color: var(--color-background-first);
  border-bottom: 1px solid var(--separator);
}
.LogoWrapper {
  height: 100%;
  border-right: 1px solid var(--color-text-white);
  display: flex;
  align-items: center;
  outline: none;
}
.Hidden {
  width: 75px;
}
.Show {
  width: 330px;
}

.Logo {
  display: flex;
  align-content: center;
  gap: 20px;
  height: 100%;
  padding: 10px 14px;
  svg {
    width: 45px;
    height: 40px;
    fill: var(--color-icon-main);
  }
}
.Smile {
  padding: 10px 10px 10px 0;
}

.hamburger {
  flex-shrink: 0;
  cursor: pointer;
  //width: 55px;
}
.Text {
  display: flex;
  align-items: center;
  color: var(--color-text-white);
  font-family: "Formular", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -1.56px;
}
.TariffInfo {
  display: flex;
  gap: 4px;
  .Number {
    color: var(--color-icon-main);
  }
}

.divTwo {
  flex: 1;
  background-color: var(--color-background-first);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 30px;
  padding-left: 10px;
  outline: none;
}

.separatorDiv {
  height: 100%;
  width: 1px;
  background-color: var(--site_line_color);
}

.adviceWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    background-color: var(--color-background-main);
    border-radius: 10px;
}
@media screen and (min-width: 1000px) {
    .advices {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        /* background-color: red; */
        border-radius: 5px;
        padding-top: 20px;
    }
}
@media screen and (max-width: 999px) {
    .advices {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 5px;
        padding-top: 20px;
    }
}


.titleDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.titleDiv img {
    width: 22px;
    height: 22px;
}

.oneAdvice {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.adviceContent {
    background-color: var(--color-background-second);
    border-radius: 5px;
    min-height: 100px;
    padding: 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.radio_btns_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: blueviolet; */
}

.one_radio_div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.one_radio_input {
    /* width: 15px;
    height: 15px;
    background-color: aqua;
    border: 1px solid green; */
    cursor: pointer;
}
.checked {
    background-color: yellow;
}
.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 380px;
  max-width: 380px;
  aspect-ratio: 1/1;
  padding: 10px;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(3, 33%);
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    display: block;
    width: 127%;
    height: 127%;
    justify-self: center;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    background: none;

    &:hover {
      transform: scale(1.1);
    }
  }
  .active {
    img {
      transform: scale(1.2);
    }
  }
}

@import '../../styles/mixins';

.Wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 15px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}
.Container {
  @include container(var(--color-background-main),var(--color-border-main));
  flex-direction: column;
  gap: 10px;
  .Title {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .Button {
    width: 50%;
    max-width: 300px;
    margin-left: auto;
    display: block;
    margin-top: auto;
    @media (max-width: 550px) {
      width: 100%;
      max-width: 100%;
    }
  }
  svg {
    stroke: var(--color-icon-main);
    fill: var(--color-icon-main);
    width: 40px;
    height: 40px;
    defs {
      linearGradient {
        stop {
          stop-color: var(--color-icon-first);
        }
      }
      radialGradient {
        stop {
          stop-color: var(--color-icon-first);
        }
      }
    }
  }
}

//@media screen and (max-width: 950px) {
//  .sectionsWrapper {
//    width: 100%;
//    display: flex;
//    flex-direction: column;
//    gap: 20px;
//    justify-content: center;
//    align-items: flex-start;
//    margin-top: 15px;
//  }
//}

@media screen and (min-width: 501px) {
  .oneFullSection {
    display: grid;
    grid-template-columns: 1fr 160px;
    border-radius: 10px;
    background-color: var(--color-background-main);
    border: 1px solid var(--color-border-main);
    color: var(--color-text-white);

    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .oneFullSection {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--color-background-main);
    border: 1px solid var(--color-border-main);
    color: var(--color-text-white);
    width: 100%;
    padding: 20px;
  }
}

@media screen and (min-width: 501px) {
  .oneSectionLeftPart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
  }
}
@media screen and (max-width: 500px) {
  .oneSectionLeftPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.leftPartTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.leftPartText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex: 1;
}

@media screen and (min-width: 501px) {
  .oneSectionRightPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .oneSectionRightPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
}

.oneSectionRightPart {

}

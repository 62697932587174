@mixin mobileSideBar {
  z-index: 1;
  @media (max-width: 1100px) {
    position: absolute;
  }
}

.ScrollBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid var(--color-text-white);
  background-color: var(--color-background-first);
  height: calc(100svh - var(--header_height));
  @media (max-width: 550px) {
    border: none;
  }
}
.Links {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.SideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  min-height: calc(100svh - var(--header_height));
  padding: 15px 10px 30px 10px ;
  @media (max-width: 550px) {
    padding: 15px;
  }

}
.Hidden {
  width: 75px;
  min-width: 75px;
}
.HiddenWrapper {
  width: 75px;
  min-width: 75px;
  @include mobileSideBar
}

.TabletWrapper {
  left: -75px;
}

.MobileWrapper {
  left: -100vw;
}

.ShowWrapper {
  width: 330px;
  min-width: 330px;
  @include mobileSideBar
}

.Show {
  z-index: 1;
  width: 330px;
  min-width: 330px;
}

.Fold {
  stroke: var(--color-icon-first);
  width: 40px;
  height: 40px;
  margin-right: 5px;
  margin-left: auto;
  cursor: pointer;
  transition: 0.3s;
  //&:hover {
  //  transform: rotateY(180deg);
  //}
}
.button {
  all: unset;
  -webkit-tap-highlight-color: transparent; /* Для Safari и старых версий Chrome */
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  gap: 5px;

  color: #ffffff;
  opacity: .6;
  font-family: Inter, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.42px;
  text-transform: uppercase;

  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
  &:focus {
    outline: none;

  }
  &:hover {
    opacity: 1;
    svg {
      transform: scale(1.2);
    }
  }
}
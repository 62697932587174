@import '../../../styles/mixins';

.LogOut {
  align-items: center;
  color: var(--color-text-danger);
  transition: 0.2s;
  &:hover {
    color: var(--color-text-danger-hover);
  }
}
.Form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  gap: 20px;

}
.Centre {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ButtonsFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

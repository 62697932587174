@mixin button {
  font-family: var(--font-family-primary);
  cursor: pointer;
  user-select: none;
  height: 42px;

  display: inline-flex;
  flex-shrink: 0;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 0.375rem 1.75rem;
  @media (max-width: 800px) {
    padding: 0.375rem 0.6rem;
  }

  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-decoration: none;

  background-color: transparent;
  border: none;
  border-radius: 0.625rem;

  transition: 0.3s;

  &:focus-visible {
    outline: 2px solid var(--color-outline-focus);
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &.fullWidth {
    justify-content: center;
    width: 100%;
  }
  img {
    right: 15px;
    width: 28px;
  }
  &.img {
    padding-right: 45px;
    @media (max-width: 800px) {
      padding-right: 0.6rem;
    }
  }
}

.primary {
  @include button;
  color: var(--color-text-black);
  background-color: var(--color-button-primary);
  &:hover:enabled {
    background-color: var(--color-button-primary-hover);
  }
  &:active:enabled {
    background-color: var(--color-button-primary-active);
  }
}

.secondary {
  @include button;
  color: var(--color-text-white);
  background-color: var(--color-button-secondary);
  &:hover:enabled {
    background-color: var(--color-button-secondary-hover);
  }
  &:active:enabled {
    background-color: var(--color-button-secondary-active);
  }
  &:disabled {
    color: var(--color-text-black);
  }
}

.tertiary {
  @include button;
  color: var(--color-text-black);
  background-color: var(--color-button-tertiary);
  border: 1px solid var(--color-accent-700);
  &:hover:enabled {
    background-color: var(--color-button-tertiary-hover);
  }

  &:active:enabled {
    background-color: var(--color-button-tertiary-active);
  }
}

.quaternary {
  @include button;
  color: var(--color-text-black);
  background-color: var(--color-button-quaternary);
  border: 1px solid var(--color-accent-700);
  &:hover:enabled {
    background-color: var(--color-button-quaternary-hover);
  }

  &:active:enabled {
    background-color: var(--color-button-quaternary-active);
  }
}

.text {
  @include button;
  color: var(--color-text-gray);
  border: 1px solid var(--color-accent-700);
  &:hover:enabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--color-text-white);
  }

  &:active:enabled {
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--color-text-white);
  }
}

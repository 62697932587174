.textAreaWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.textArea {
    background-color: var(--textField_bgColor);
    color: var(--textField_textColor);
    width: 100%;
    max-height: 200px;
    
    padding: 5px 10px;
    border-radius: var(--textField_borderRadius);
    font-size: 16px;
    text-decoration: none;

    resize: vertical;
}
.textArea::placeholder {
    font-family: 'Inter';
    font-size: 14px;
    font-style: italic;
}
.error {
    background-color: var(--textField_error_bgColor);
}
.search_and_fold_wrapper {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 5px;
}
.search_and_fold_wrapper_row {
    flex-direction: row;
}
.search_and_fold_wrapper_col {
    flex-direction: column;
}

.search_icon_class {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.Fold {
    stroke: var(--color-icon-first);
    width: 40px;
    height: 40px;
    margin-right: 5px;
    margin-left: auto;
    cursor: pointer;
    transition: 0.3s;
}

.search_line_div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    /* background-color: yellow; */
}

.search_text_div {
    z-index: 100;
    position: absolute;
    top: 45px;
    left: 0;

    width: 100%;
}

.lineTextField {
    height: var(--textField_height);
    border-radius: 5px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
}
.lineTextField::placeholder {
    font-size: 14px;
    font-style: italic;
}

.searched_categories_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 10px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    /* background-color: green; */
    background-color: black;
    color: white;

    overflow-x: hidden;
    overflow-y: scroll;

    max-height: 300px;
}
.searched_category_element {
    padding: 3px;
    border-radius: 5px;
    background-color: rgb(72, 71, 71);
    color: white;
    cursor: pointer;
}
.searched_category_element:hover {
    background-color: white;
    color: black;
    scale: 1.02;
}

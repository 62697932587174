.react-joyride__overlay {
  max-height: 100svh !important;
}

.react-joyride__beacon {
  display: none !important;
}

.react-joyride__spotlight {
  //border: 1px solid #99e67c;
}

.textSection {
    padding: 0 10px 20px 10px;
    overflow: hidden;
}
.titleDiv {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--gptAnswer_titleColor);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.fullTextDiv {
    color: var(--gptAnswer_textColor);
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    width: 100%;

    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.markdown_class {
    color: black;
}
.markdown_class table {
    border: 1px solid black;
    border-collapse: collapse;
}
.markdown_class th {
    border: 1px solid black;
}
.markdown_class td {
    border: 1px solid black;
    padding: 5px;
}
.markdown_class h1 { font-size: 1.5em; }
.markdown_class h2 { font-size: 1.3em; }
/* .markdown_class h3 { font-size: .6em; color: red; }
.markdown_class h4 { font-size: .4em; color: red; }
.markdown_class h5 { font-size: .2em; color: red; } */
.lowerButtonsDiv {
    width: 100%;
    /* display: grid;
    grid-template-columns: 2fr 1fr; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.createShablonButtonAddClass {
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;

    /* color: var(--color-text-white); */
    color: #FFF;
    background-color: #4F4F4F;
}
.clearButtonAddClass {
    background-color: #4F4F4F;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btn_class {
    width: 170px;
}
.PasswordsContainer {
  padding: 10px;
  width: 100%;
}
.ButtonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  gap: 20px;
}

* {
  --header_height: 60px;
  --footer_height: 30px;

  font-family: "Inter", sans-serif;
  --font-family-primary: "Inter", sans-serif;

  /* OUTLET */
  --border-radius-main: 20px;

  /* TextField */
  --textField_height: 50px;
  --phoneField_iconWidth: 40px;

  --textField_borderRadius: 10px;
  --textArea_fontSize: 18px;

  /* Auth Modules:  */
  --auth_bgColor: rgb(72, 86, 87);
  --auth_Frame_bgColor: #d9d9d9;
  --auth_Frame_borderRadius: 20px;
  --auth_Frame_padding_Top_Bottom: 30px;
  --auth_Frame_padding_Left_Right: 20px;
  --auth_Frame_gap: 7px;
  --auth_Registration_gap: 3px;
  --auth_margin_topBottom: 50px;
}

html[data-theme="dark"] {
  --site_line_color: #ffff;
  /* HEADER AND FOOTER */
  --header_textColor: white;
  --footer_textColor: white;
  --header_theme_icon_bgColor: #3d4249;

  /* side menu */
  --sidmenu_mobile_cat_bgColor: #292d32;
  --sidemenu_mobile_subcat_bgColor: #d9d9d9;
  --sidemenu_mobile_subcat_textColor: black;

  /* Gpt answer/reponse */
  --gptAnswer_bgColor: #d9d9d9;
  --gptAnswer_titleColor: #000000;
  --gptAnswer_textColor: #717884;
  --gptAnswer_button_bgColor: #fff;

  /* side menu  */
  --side_menu_section_bgColor: #3d4249;
  --side_menu_section_textColor: #e6e6e6;
  --side_menu_section_Active: #5e6773;
  --side_menu_section_mouse_over: #5e6773;
  --side_menu_section_selected: #3d6aa9;

  /* text field */
  --textField_bgColor: #d9d9d9;
  --textField_textColor: black;
  --textField_error_bgColor: rgb(199, 143, 143);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

header {
  color: var(--header_textColor);
  height: var(--header_height);
  width: 100%;
}
.mainSection {
  background-color: var(--color-background-main);
  flex: 1;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: pre-line;
}

.ScrollBar {
  max-height: calc(100svh - var(--header_height));
  height: calc(100svh - var(--header_height));
}

.outletDiv {
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  @media (max-width: 550px) {
    padding: 0;
  }
}

footer {
  position: relative;
  bottom: 0;
  color: var(--footer_textColor);
  width: 100%;
}

.separatorDiv {
  min-height: 1px;
  background-color: var(--site_line_color);
}
.separatorVerticalDiv {
  /*height: 100%;*/
  background-color: var(--site_line_color);
  width: 1px;
}

.card {
  display: flex;
  background-color: var(--color-background-main);
  flex-direction: column;
  margin: 0 5px;
  color: var(--color-text-white);
  border-radius: 10px;
  -webkit-box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
  box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.27);
  .imgWrapper {
    position: relative;
    img {
      max-width: 100%;
      border-radius: 10px 10px 0 0;
      aspect-ratio: 16 / 9;
      width: 100%;
      display: block;
      object-fit: cover;
    }
    .iconCards {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 10px;
      bottom: 10px;
      filter: drop-shadow(0 0 5px  #0c0c0c);
    }
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 255px;
    padding: 10px;
    .title {
      height: 44px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .description {
      overflow: hidden;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      text-overflow: ellipsis;
    }
  }
}
.container{
    background-color: var(--color-background-main);
    border: 1px solid var(--color-border-main);
    border-radius: 0.625rem;
    padding: 1.25rem;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.first {
    background-color: var(--color-background-first);
    border: 1px solid var(--color-border-first);
}
.second {
    background-color: var(--color-background-second);
    border: 1px solid var(--color-border-second);
}
.third {
    background-color: var(--color-background-third);
    border: 1px solid var(--color-border-third);
}
.model_selection_wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;

    background-color: var(--color-background-second);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.model_selection_grid_div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.model_selection_field {
    position: relative;
    
    width: 100%;
    min-height: 50px;
    background-color: #3D4348;
    box-sizing: border-box;
    padding: 15px 10px 15px 10px;
    border-radius: 10px;

    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-rows: 40px 40px;
    place-items: center;

    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;

    cursor: pointer;
    transition: var(--transition-short);
}
.unselected {
    border: 2px solid rgba(0,0,0,0);
}
.selected {
    border: 2px solid var(--color-button-primary);
    color: var(--color-button-primary);
}
.svg_selected_class {
    fill: var(--color-button-primary);
    transition: var(--transition-short);
}
.svg_unselected_class {
    fill: var(--color-button-primary-white);
    transition: var(--transition-short);
}


.model_selection_label_1 {
    margin-top: 10px;
}
.model_selection_label_2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    color: #81848B;
}
.model_selection_label_soon {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    color: #AAFD8C;
}
.titleLineWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.titleLineLeftPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  color: var(--color-text-white);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleLineRightPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    width: 40px;
    height: 40px;
    fill: var(--color-icon-main);
  }
  @media (max-width: 550px) {
    display: none;
    width: 0;
    height: 0;
  }
}

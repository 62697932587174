.OneQuestionSemiOptional_wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.OneQuestionSemiOptional_option_div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}
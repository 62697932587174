.contentBlockSubCategoryV2Mobile_wrapper {
    margin-top: 50px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 15px;

    /* background-color: red; */
}

.contentSwitcher_class {
    /* background-color: yellow; */
    width: 100%;
    display: grid;
    /* flex-direction: grid; */
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    color: var(--color-text-white);
}
.switcher_option {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    /* background-color: blue; */
    border-radius: 5px;
    border: 1px solid white;
}
.switcher_option_selected {
    /* background-color: green; */
    background-color: var(--color-background-first);
}


.subCatTitleDiv {
    display: grid;
    /* grid-template-columns: 1fr 45px; */
    column-gap: 10px;
    align-items: center;
    margin-bottom: 10px;

    color: var(--color-text-white);
}
.subCatTitleDiv img {
    width: 40px;
    height: 40px;
}
@media screen and (min-width: 621px) {
    .title_grid_template { grid-template-columns: 1fr 45px; }
}
@media screen and (max-width: 620px) {
    .title_grid_template { grid-template-columns: 1fr; }
}

.subCatDescriptionNotice {
    font-family: 'Courier New', Courier, monospace;
    font-style: italic;
    margin: 7px 0;
    font-size: 13px;
}
@media screen and (min-width: 595px) {
    .oneHistorySection {
        display: grid;
        grid-template-columns: 20px 150px 1fr 1fr 40px;
        column-gap: 10px;
        /* background-color: blueviolet; */
        background-color: var(--color-background-main);
        width: 100%;
        overflow: hidden;
        padding: 5px;
        border-radius: 5px;
    }
}
@media screen and (max-width: 594px) {
    .oneHistorySection {
        /* display: grid;
        grid-template-columns: 20px 150px 1fr 1fr 40px;
        column-gap: 10px; */
        display: flex;
        flex-direction: column;
        gap: 5px;

        background-color: var(--color-background-main);
        width: 100%;
        overflow: hidden;
        padding: 5px;
        border-radius: 5px;
    }
}

.oneHistorySectionShort {
    max-height: 50px;
}


.oneHistoryField {
    width: 100%;
    display: flex;
    /* flex-direction: row;
    justify-content: center;
    align-items: flex-start; */

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* background-color: green; */
    padding: 10px 0;

    text-align: justify;
}
.oneHistoryField img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.oneHistoryField_mobile_show {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.oneHistoryQuestionsDiv {
    display: flex;
    flex-direction: column;
    /* background-color: blueviolet; */
    text-align: justify;
    padding: 10px 0;
    img {
        width: 100%;
    }
}
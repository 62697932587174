.promo_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.button {
    padding: 0 4px;
    svg {
        fill: var(--color-icon-first);
        width: 30px;
    }
}

.img_class {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
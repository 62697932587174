
.waiting {
    position: fixed;
    top: 50%;
    left: 50%;
}

.Modal {
    width: 80%;
    max-width: 1000px;
    max-height: 100%;
    @media (max-width: 550px) {
        width: 95%;
    }
    /*max-height: 40%;*/
}
.Scroll {
    height: calc(100% + 40px);
    width: 100%;
    border-radius: 10px;
    padding: 0 20px;
}

.Sections {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 400px;
}

.politicsWrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    color: var(--color-text-white);
    border: 1px solid #FFF;
    border-radius: 10px;
    font-size: 14px;
 a {
    text-decoration: underline;
     color: var(--color-text-white);
 }
  .container {
    display: flex;
    align-items: start;
    gap: 5px;
  }
}
.error {
  border: 2px solid rgb(185, 54, 54);;
}
.politicsErrorDiv {
    width: 100%;
    text-align: center;
    color: rgb(185, 54, 54);
}
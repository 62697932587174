.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100svh;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--color-text-white);
    width: 90%;
    height: 90%;
    gap: 20px;
    img {
      max-width: 100%;
      max-height: 70%;
    }
    p {
      text-align: center;
    }
  }
}
.isLimitation_layout {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
}

.isLimitation_wrapper {
    position: absolute;
    top: 150px;
    left: calc(50% - 125px);
    width: 250px;

    padding: 20px;
    background-color: var(--color-background-main);

    border: 1px solid wheat;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    text-align: center;
}
.isLimitation_wrapper h3 {margin: 0;}
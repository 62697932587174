.localTextStat_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 5px;
}

.show_hide_div {
    color: rgb(164, 163, 163);
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.stat_div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: black;
    font-size: 14px;
}
.Avatar {
  cursor: pointer;
  width: 80%;
  max-width: 250px;
  height: auto;
  aspect-ratio: 1 / 1;
  background-color: var(--color-background-second);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 35px 10px var(--color-shadow-first) inset;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 0 35px 25px var(--color-shadow-first-hover) inset;
  }

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.Change {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  aspect-ratio: 1 / 1;
  width: 100%;
  transition: 0.3s;
  height: auto;
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

.small {
  width: 45px;
  height: 45px;
}

.middle {
  width: auto;
  height: 125px;
  .Change {
    transform: scale(0.8);
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: var(--color-text-white);
}

.titleDiv {
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.titleDiv h3 {
  margin: 0;
}

.questionsDiv {
  background-color: var(--color-background-second);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}
.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.showHideDiv {
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
}
.showHideImg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.under_construction_div {
  text-align: center;
}
.under_construction_div h3 {
  margin: 0;
}

.uploader {
  cursor: pointer;
  width: auto;
  height: 200px;
  border: 2px dashed var(--color-text-gray);
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 0.625rem;
  color: var(--color-text-gray);
  transition: .2s;
  &:hover {
    border-color: var(--color-text-white);
    color: var(--color-text-white);
    svg {
      transform: scale(1.1);
      stroke: var(--color-text-white);
    }
  }
  img {
    opacity: 1;
    max-height: 400px;
    max-width: 100%;
  }
  svg {
    margin-top: 35px;
    pointer-events: none;
    transition: .2s;
    stroke: var(--color-text-gray);
    height: 60px;
    stroke-width: 1px;
  }
  p {
    pointer-events: none;
  }
  .fileInput {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
}
.drag  {
  border-color: var(--color-text-white);
  color: var(--color-text-white);
  svg {
    transform: scale(1.1);
    stroke: var(--color-text-white);
  }
}

.previewImage {
  padding: 5px;
  height: auto;
}

.file_name_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.file_name_div img {
  width: 24px;
  height: 24px;
}
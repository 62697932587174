.historyWrapper {
    padding: 50px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-background-first);
    color: var(--color-text-white);
    border-radius: 20px;
}
.historyWrapper h3 {margin: 0;}

.back_btn_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

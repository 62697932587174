.answerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    color: var(--color-text-white);
    background-color: var(--color-background-main);
    border-radius: 20px;
}
.answerTitleDiv {
    width: 100%;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.answerTitleDiv h3 { margin: 0; }

.answersDiv {
    background-color: var(--color-background-second);
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gptResponseTextTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Inter;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.gptResponseText {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn_class_div_menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}
.darkBtnClass {
    background-color: #4F4F4F;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
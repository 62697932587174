.root {
  width: 100%;
}

.fieldContainer {
  position: relative;
  width: 100%;
}

.field {
  box-sizing: border-box;
  padding: 12px 10px 12px 10px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: var(--font-size-s);
  color: var(--color-text-white);
  //box-shadow: 0 0 35px 10px rgba(0, 0, 0, 0.5) inset;
  box-shadow: 0 0 20px 8px var(--color-shadow-first) inset;
  background: var(--color-background-first);
  border-radius: 0.625rem;
  border: 1px solid var(--color-border-first);
  outline: 0;
  transition: 0.3s;

  &::placeholder {
    color: var(--color-text-placeholder);
  }

  &:focus-visible {
    border: 1px solid var(--color-text-white);
    outline: 1px solid var(--color-outline-focus);
  }

  &:hover {
    filter: brightness(1.1);
    background: var(--color-background-first);
  }

  &.error {
    border-color: var(--color-text-danger);
  }
}

.Error {
  margin-top: 4px;
  height: 20px;
}
.password {
  padding: 12px 40px 12px 10px;
}

.isDefaultValue {
  color: var(--color-text-placeholder);
  &:focus-visible {
    color: var(--color-text-white);
  }
}

.label {
  margin-bottom: 5px;

  color: var(--color-text-white);
}

.showPassword {
  cursor: pointer;
  position: absolute;
  bottom: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  svg {
    fill: var(--color-text-placeholder);
    transition: 0.4s;
    &:hover {
      fill: var(--color-text-white);
    }
  }
  width: 20px;
  height: 20px;
  margin-right: 12px;
  padding: 0;

  background: none;
  border: 0;
  outline: 0;

  &:focus-visible {
    outline: var(--outline-focus);
  }
}

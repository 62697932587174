@mixin typography($fontSize, $lineHeight, $fontWeight) {
  margin: 0;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  color: var(--color-light-100);
}
@mixin profileForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  min-height: 460px;
  max-width: 315px;
  min-width: 315px;
  width: 100%;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    width: 100%;
    min-width: 260px;
  }
}

@mixin container($backColor, $borderColor){
  display: flex;
  background-color: $backColor;
  border: 1px solid $borderColor;
  border-radius: 0.625rem;
  padding: 1.25rem;
}

@mixin flex(
  $direction: row,
  $justify: flex-start,
  $align: stretch,
  $gap: 0,
  $wrap: nowrap
) {
  display: flex;
  flex-flow: $direction $wrap;
  flex-wrap: $wrap;
  gap: $gap;
  align-items: $align;
  justify-content: $justify;
}

.mini_header_top_line {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 5px;

    /* font-weight: 500;
    line-height: 22px;
    font-size: 16px; */
    /* font-family: Inter; */
    

    align-items: flex-start;
}
.mini_header_top_line_question_icon {
    position: relative;
}
.mini_header_class {
    /* font-size: 18px;
    font-weight: 500;
    line-height: 22px; */

    /* margin-bottom: 20px; */

    /* font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */

    font-size: 16px;
    font-weight: bold;
    line-height: normal;
}
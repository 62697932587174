.productSectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    padding: 20px;
    @media (max-width: 550px) {
        border-radius: 0;
    }
    border-radius: 10px;
    background-color: var(--color-background-first);
    border: 1px solid var(--color-border-first);
    color: var(--color-text-white);
}
.productSectionWrapper h4 {margin: 0; text-align: center;}
.productSectionWrapper h3 {margin: 0; text-align: center;}

/* .titleDiv {
    width: 100%;
    text-align: center;
} */

.products_div {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1200px) {
    .prod_grid_areas { grid-template-columns: 1fr; }
}
.Section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--color-background-main);
    border-radius: 10px;
    padding: 10px 20px;
}


.oneSectionHead {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 1fr 70px 30px;
}
.oneSectionHead_title {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 30px;
    justify-content: flex-start;
}
.cross_icon_div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}
.cross_icon_div img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}


.imgDiv {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 10px;
    background-color: rgb(157, 157, 157);
}
.imgDiv img {width: 25px; height: 25px;}
/* .imgDiv_lightBG {
    background-color: rgb(157, 157, 157);
} */

.oneSectionMenuBtns {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.oneSectionMenuBtns img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.oneQuestion_class {
    margin: 10px;
}
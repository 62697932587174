.backButton_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid var(--site_line_color);
    border-radius: 5px;

    cursor: pointer;

    padding: 5px 10px;
}
.backButton_wrapper label {
    cursor: pointer;
}
:root {
  --font-family-primary: "Inter", sans-serif;

  // line heights
  --line-height-xs: 18px;
  --line-height-s: 20px;
  --line-height-m: 22px;
  --line-height-l: 34px;
  --line-height-xl: 38px;

  // font sizes
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 22px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 30px;

  // font weights
  --font-weight-regular: 400;
  --font-weight-bold: 600;
  --font-weight-large: 900;
}

@mixin typography($fontSize, $lineHeight, $fontWeight) {
  font-family: var(--font-family-primary);
  margin: 0;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  user-select: none;
  color: var(--color-text-white);
}

.large {
  @include typography(
    var(--font-size-xxxl),
    var(--line-height-xl),
    var(--font-weight-large)
  );
}

.h1 {
  @include typography(
    var(--font-size-xxl),
    var(--line-height-l),
    var(--font-weight-bold)
  );
}

.h2 {
  @include typography(
    var(--font-size-xl),
    var(--line-height-m),
    var(--font-weight-bold)
  );
}

.h3 {
  @include typography(
    var(--font-size-l),
    var(--line-height-m),
    var(--font-weight-bold)
  );
}

.body1 {
  @include typography(
    var(--font-size-l),
    var(--line-height-m),
    var(--font-weight-regular)
  );
}

.body2 {
  @include typography(
    var(--font-size-m),
    var(--line-height-m),
    var(--font-weight-regular)
  );
}

.subtitle1 {
  @include typography(
    var(--font-size-m),
    var(--line-height-m),
    var(--font-weight-bold)
  );
}

.subtitle2 {
  @include typography(
    var(--font-size-s),
    var(--line-height-s),
    var(--font-weight-regular)
  );
}

.caption {
  @include typography(
    var(--font-size-xs),
    var(--line-height-s),
    var(--font-weight-regular)
  );
}

.overline {
  @include typography(
    var(--font-size-xs),
    var(--line-height-s),
    var(--font-weight-bold)
  );
}

.link1,
.link1:visited {
  @include typography(
    var(--font-size-s),
    var(--line-height-m),
    var(--font-weight-regular)
  );

  cursor: pointer;
  color: var(--color-info-500);
  text-decoration: underline;
}

.link2,
.link2:visited {
  @include typography(
    var(--font-size-xs),
    var(--line-height-s),
    var(--font-weight-regular)
  );

  cursor: pointer;
  color: var(--color-info-500);
  text-decoration: underline;
}

.error {
  @include typography(
    var(--font-size-s),
    var(--line-height-xs),
    var(--font-weight-regular)
  );

  color: var(--color-text-danger);
}

.search_wrapper {
    width: 100%;
    /* min-height: 30px; */

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 20px;

    /* background-color: blue; */
}

.history_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    /* border-radius: 5px;
    border: 1px solid yellow; */
}
.history_head h4 {margin: 0;}

.imgDiv {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    margin: 0 10px;
    background-color: rgb(157, 157, 157);
}
.imgDiv img {width: 25px; height: 25px;}

.all_search_options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 300px;
}
.one_search_option {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* align-items: center; */
  
}

.textFieldClass {
    height: 30px;
    padding: 0 5px;
    background-color: beige;
    color: black;
    font-size: 16px;
    border-radius: 7px;
}
html {
  box-sizing: border-box;
  //font-size: 62.5%; // 1 rem = 10px
  background-color: var(--color-background-first);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; /* Для Safari и старых версий Chrome */
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  -webkit-tap-highlight-color: transparent; /* Для Safari и старых версий Chrome */
  user-select: none;
}
svg {
  flex-shrink: 0;
}

a,
a:visited {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  height: 100svh;
  color: var(--color-text-white);
}

.fromWrapper {
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100svh - var(--header_height));
  background-image: url("../../assets/images/background-auth.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  .loginForm {
    max-width: 440px;
    margin: 50px 20px 30px 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color-background-first);
    color: var(--color-text-white);
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 480px) {
      padding: 20px 10px;
      margin: 10px;
    }
  }
}


.textFields {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.annotationDiv {
  font-family: "Inter", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.regAnnouncementDiv {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-content: center;
}
.Reg {
  background-color: #37ade0;
  color: white;
}
.regAnnouncementDiv img {
  width: 25px;
  height: 25px;
}
.regButtonClass {
  text-decoration: none;
}
.regBotnameDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.regBotnameDiv img {
  /*width: 25px;*/
  /*height: 25px;*/
}

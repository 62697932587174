.oneGptAnswerWrapper {
    border-radius: 10px;
    margin-top: 10px;
    background-color: var(--gptAnswer_bgColor);
    overflow: hidden;
}
.gprAnswerShort {
    display: flex;
    flex-direction: column;
    height: 190px;
}
.gprAnswerWide {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 190px;
}

.image {
    padding: 10px 0;
    max-height: 400px;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
}   
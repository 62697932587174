.contentSubCatWrapper {
  background-color: var(--color-background-first);
  color: var(--color-text-white);
  padding: 20px 10px;
  width: 100%;
  border-radius: 20px;
  @media (max-width: 550px) {
    border-radius: 0
  }
}

.subCatTitleDiv {
  display: grid;
  grid-template-columns: 1fr 45px;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.subCatTitleDiv img {
  width: 40px;
  height: 40px;
}

.subCatBackTitle_div {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
.subCatBackTitle_div h3 {
  text-align: center;
}
.subCatBackTitle_div img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.subCatTitleDiv_title {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subCatDescriptionNotice {
  margin: 7px 0;
  font-size: 14px;
  color: grey;
}

.subCatDisplay {
  display: grid;
  row-gap: 0;
  column-gap: 20px;
}
.subCatDisplayTemplateQuestionsShow {
  grid-template-columns: 0.7fr 1fr;
}
.subCatDisplayTemplateQuestionsHide {
  grid-template-columns: 60px 1fr;
}

.contentSection {
  background-color: var(--color-background-main);
  color: var(--color-text-white);
  border-radius: 20px;
  padding: 20px 10px;
  @media (max-width: 550px) {
    border-radius: 0
  }
}

.buttonsDiv_Flex1Element {
  flex: 1;
}

.buttonAdditionalClass {
  /* border-radius: 5px; */
  background-color: #303030;
  color: #fff;
  /*outline: none;*/
  border: 0;
  width: auto;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
}

.aClass {
  text-decoration: none;
}

.CloseButton {
  //position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.3s;
  padding: 20px 20px 0 0;
  margin-left: auto;
  border: none;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    transition: 0.3s;
    opacity: 0.7;
    fill: var(--color-icon-first);
  }
  &:hover {
    svg {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

.greenLightDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    
    margin: 5px;

    background-color: rgb(12, 156, 12);
    border-radius: 50px;

    font-size: 14px;
    color: black;
}
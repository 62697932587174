:root {
  // background
  --color-background-main: #3d4249;
  --color-background-first: #292d32;
  --color-background-second: #292E32;
  --color-background-third: #454545;
  //border
  --color-border-main: #3d4249;
  --color-border-first: #292d32;
  --color-border-second: #303030;
  --color-border-third: #454545;
  //icons
  --color-icon-main: #abfd8d;
  --color-icon-first: #ffffff;
  --color-icon-second: #000000;
  //buttons
  --color-button-primary: #ffc801;
  --color-button-primary-white: #ffffff;
  --color-button-primary-hover: #ffd134;
  --color-button-primary-active: #edb800;
  --color-button-secondary: #292d32;
  --color-button-secondary-hover: #2f3137;
  --color-button-secondary-active: #25292e;
  --color-button-tertiary: #fff;
  --color-button-tertiary-hover: #efefef;
  --color-button-tertiary-active: #e1e1e1;
  --color-button-quaternary: #abfd8d;
  --color-button-quaternary-hover: #99e67c;
  --color-button-quaternary-active: #79d856;
  --color-button-text-white: #ffffff;
  --color-button-text-black: #000000;
  //texts
  --color-text-white: #ffffff;
  --color-text-white-disabled: #4c4c4c;
  --color-text-gray: #c5c4c4;
  --color-text-black: #000000;
  --color-text-placeholder: #6b6b6b;
  --color-text-additional: #abfd8d;
  --color-text-danger: #ea3434;
  --color-text-danger-hover: #fa0000;
  //shadow
  --color-shadow-first: #1a1a1e;
  --color-shadow-first-hover: #14161a;
  //scroll
  --color-scroll: #848484;
  //assets
  --hover-item: #5E6B7D;
  --active-item: #5E7597;
  --separator: #ffffff;
}

:root .light-mode {
  // background
  --color-background-main: #f6f6f8;
  --color-background-first: #E9F1FA;
  --color-background-second: #7a7171;
  --color-background-third: #a28e8e;
  //border
  --color-border-main: #b9c5d3;
  --color-border-first: #b9c5d3;
  --color-border-second: #b9c5d3;
  --color-border-third: #b9c5d3;
  //icons
  --color-icon-main: #00ABE4;
  --color-icon-first: #000000;
  --color-icon-second: #ffffff;
  //buttons
  --color-button-primary: #1975d0;
  --color-button-primary-hover: #186dc0;
  --color-button-primary-active: #145aa1;
  --color-button-secondary: #292d32;
  --color-button-secondary-hover: #2f3137;
  --color-button-secondary-active: #25292e;
  --color-button-tertiary: #fff;
  --color-button-tertiary-hover: #efefef;
  --color-button-tertiary-active: #e1e1e1;
  --color-button-quaternary: #abfd8d;
  --color-button-quaternary-hover: #99e67c;
  --color-button-quaternary-active: #79d856;
  --color-button-text-white: #ffffff;
  --color-button-text-black: #000000;
  //texts
  --color-text-white: #000000;
  --color-text-white-disabled: #4c4c4c;
  --color-text-gray: #c5c4c4;
  --color-text-placeholder: #757575;
  --color-text-additional: #00aae2;
  //shadow
  --color-shadow-first: #e1edf5;
  --color-shadow-first-hover: #e7e3e3;
  //scroll
  --color-scroll: #848484;
  //assets
  --hover-item: #a5bfda;
  --active-item: #96b2da;
  --separator: #000000;
}